import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    $(this.element).on('period-selected', this.handlePeriodSelected);
  }

  handlePeriodSelected = () => {
    $(this.element).trigger('filter.parkcms');
  };
}
