import { Controller } from '@hotwired/stimulus';

const DEFAULT_CONSENT = {
  ad_personalization: 'denied',
  ad_storage: 'denied',
  ad_user_data: 'denied',
  analytics_storage: 'denied',
  functionality_storage: 'denied',
  personalization_storage: 'denied',
  security_storage: 'granted',
  wait_for_update: 500,
};

const PRIVACY_PATHS = [
  '/privacy',
  '/privacy_cookies',
  '/voorwaarden-verblijf',
  '/declaration-de-confidentialite',
  '/de/datenschutz-bestimmungen-1',
  '/datenschutz-und-cookieerklarung',
  '/cookie-verklaring',
  '/datenschutzerklearung',
  '/de/freizeitpark/privacy-datenschutzerklaerung/',
  '/colophon',
  '/privacy-verklaring',
  '/datenschutz-und-cookie-erklaerung',
  '/privacy-statement',
  '/privacybeleid/',
  '/privacy-cookies',
  '/privacy-policy',
  '/datenschutzerklarung',
  '/en/Privacypolicy',
  '/privacy-beleid',
  '/privacy-and-cookie-statement',
  '/de/datenschutzrichtlinie',
  '/de/datenschutzerklaerung',
  '/impressum-and-datenschutz',
  '/de/privacy',
  '/dichiarazione-sulla-privacy-e-sui-cookie',
  '/cookies',
  '/privacy-cookies/',
  '/privacy-en-cookiestatement',
  '/attractiepark/privacy-verklaring/',
  '/fr/politique-de-confidentialite-2',
  '/nb/personvernerklaering',
  '/datenschutzerklaerung',
  '/de/datenschutzerklarung',
  '/disclaimer-and-privacy-statement',
  '/privacybeleid',
  '/datenschutz-bestimmungen',
  '/cookies-op-website',
  '/privacystatement',
  '/nl/privacybeleid-1',
  '/datenschutz',
  '/es/privacidad',
  '/personvernerklaering',
  '/politique-de-confidentialite',
  '/nl/privacy',
  '/de/datenschutzbestimmungen',
  '/privacy-en-cookieverklaring',
  '/fr/Politiquedeconfidentialite',
  '/en/privacy-policy-1',
  '/en/privacy',
  '/cookie-policy',
  '/privacyverklaring',
  '/cookiestatement',
];

export default class extends Controller {
  static targets = [
    'customizePanel',
    'acceptAllButtonPrimary',
    'acceptAllButtonSecondary',
    'saveButton',
    'toggleCustomizePanelButton',
    'declineButton',
    'consentNecessary',
    'consentPreferences',
    'consentStatistics',
    'consentMarketing',
  ];

  connect() {
    window.dataLayer = window.dataLayer || [];

    const currentPath = window.location.pathname;

    this.pushDataLayer('consent', 'default', DEFAULT_CONSENT);
    this.pushDataLayer('set', 'ads_data_redaction', true);
    this.pushDataLayer('set', 'url_passthrough', false);

    if (!this.consentCookieExists() && !this.isSpider() && !PRIVACY_PATHS.includes(currentPath)) {
      this.openDialog();
    } else {
      const consentValues = this.getConsentCookieValues();
      if (
        consentValues &&
        consentValues.preferences !== undefined &&
        consentValues.statistics !== undefined &&
        consentValues.marketing !== undefined
      ) {
        this.signalGoogleConsentAPI('dynamic', consentValues.preferences, consentValues.statistics, consentValues.marketing);
      } else {
        console.warn('Invalid consent values:', consentValues);
      }
    }
  }

  isSpider() {
    return /adidxbotc|Applebot\/|archive.org_bot|asterias\/|Baiduspider\/|bingbot\/|BingPreview\/|DuckDuckBot\/|FAST-WebCrawler\/|Feedspot|Feedspotbot\/|Google Page Speed Insights|Google PP|Google Search Console|Google Web Preview|Googlebot\/|Googlebot-Image\/|Googlebot-Mobile\/|Googlebot-News|Googlebot-Video\/|Google-SearchByImage|Google-Structured-Data-Testing-Tool|Chrome-Lighthouse|heritrix\/|iaskspider\/|Mediapartners-Google|msnbot\/|msnbot-media\/|msnbot-NewsBlogs\/|msnbot-UDiscovery\/|PTST\/|SEMrushBot|special_archiver\/|Siteimprove|Y!J-ASR\/|Y!J-BRI\/|Y!J-BRJ\/YATS|Y!J-BRO\/YFSJ|Y!J-BRW\/|Y!J-BSC\/|Yahoo! Site Explorer Feed Validator|Yahoo! Slurp|YahooCacheSystem|Yahoo-MMCrawler\/|YahooSeeker\/|aabot\/|compatible; aa\/|PetalBot\/|Prerender\/|webvitals.dev/.test(
      navigator.userAgent
    );
  }

  acceptAll() {
    const consent = {
      necessary: true,
      preferences: true,
      statistics: true,
      marketing: true,
      method: 'implied',
      ver: 1,
      utc: Date.now(),
    };

    this.handleConsentActions('acceptAll', 'implied', consent);
  }

  declineAll() {
    const consent = {
      necessary: true,
      preferences: false,
      statistics: false,
      marketing: false,
      method: 'explicit',
      ver: 1,
      utc: Date.now(),
    };

    this.handleConsentActions('declineAll', 'explicit', consent);
  }

  handleConsentActions(action, method, consent) {
    this.signalGoogleConsentAPI('dynamic', consent.preferences, consent.statistics, consent.marketing);
    this.trackConsentChange(action, method, consent.preferences, consent.statistics, consent.marketing);
    this.updateConsentCookies(consent);
    this.closeDialog();
  }

  getConsentValues() {
    return {
      necessary: this.consentNecessaryTarget.checked,
      preferences: this.consentPreferencesTarget.checked,
      statistics: this.consentStatisticsTarget.checked,
      marketing: this.consentMarketingTarget.checked,
      method: 'explicit',
      ver: 1,
      utc: Date.now(),
    };
  }

  save() {
    try {
      const consentValues = this.getConsentValues();

      if (!consentValues || typeof consentValues !== 'object') {
        throw new Error('Invalid consent values');
      }

      const { preferences, statistics, marketing } = consentValues;

      if (typeof preferences !== 'boolean' || typeof statistics !== 'boolean' || typeof marketing !== 'boolean') {
        throw new Error('Invalid consent values types');
      }

      const consent = {
        necessary: true,
        preferences: preferences,
        statistics: statistics,
        marketing: marketing,
        method: 'explicit',
        ver: 1,
        utc: Date.now(),
      };

      this.handleConsentActions('save', 'explicit', consent);
    } catch (error) {
      console.error('Error saving consent:', error);
    }
  }

  trackConsentChange(action, method, preferences, statistics, marketing) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'BexConsentManager',
      action: action,
      method: method,
      preferences: preferences,
      statistics: statistics,
      marketing: marketing,
    });
  }

  signalGoogleConsentAPI = function (dataRedactionMode = 'dynamic', preferences, statistics, marketing) {
    this.pushDataLayer('consent', 'update', {
      ad_storage: marketing ? 'granted' : 'denied',
      ad_user_data: marketing ? 'granted' : 'denied',
      ad_personalization: marketing ? 'granted' : 'denied',
      personalization: marketing ? 'granted' : 'denied',
      analytics_storage: statistics ? 'granted' : 'denied',
      functionality_storage: preferences ? 'granted' : 'denied',
      personalization_storage: preferences ? 'granted' : 'denied',
      security_storage: 'granted',
    }),
      'dynamic' === dataRedactionMode && this.pushDataLayer('set', 'ads_data_redaction', !marketing);
  };

  toggleCustomizeConsent() {
    // Ensure the DOM elements exist before accessing their styles
    if (
      !this.customizePanelTarget ||
      !this.saveButtonTarget ||
      !this.declineButtonTarget ||
      !this.toggleCustomizePanelButtonTarget ||
      !this.acceptAllButtonPrimaryTarget ||
      !this.acceptAllButtonSecondaryTarget ||
      !this.consentPreferencesTarget
    ) {
      console.error('One or more DOM elements are missing.');
      return;
    }

    // Check if the customize panel is currently displayed
    const isDisplayed = this.customizePanelTarget.style.display === 'block';

    this.acceptAllButtonPrimaryTarget.style.display = isDisplayed ? 'block' : 'none';
    this.acceptAllButtonSecondaryTarget.style.display = isDisplayed ? 'none' : 'block';

    // Toggle the display of the customize panel
    this.customizePanelTarget.style.display = isDisplayed ? 'none' : 'block';

    // Toggle the display of the save button
    this.saveButtonTarget.style.display = isDisplayed ? 'none' : 'block';

    // Toggle the display of the decline button
    this.declineButtonTarget.style.display = isDisplayed ? 'none' : 'block';

    // Toggle the display of the customize panel button
    this.toggleCustomizePanelButtonTarget.style.display = isDisplayed ? 'block' : 'none';

    // Scroll the consent preferences target into view if the customize panel is displayed and on mobile
    if (!isDisplayed && this.consentPreferencesTarget && window.innerWidth <= 768) {
      this.consentPreferencesTarget.scrollIntoView({ behavior: 'smooth' });
    }
  }

  pushDataLayer() {
    // Ensure window.dataLayer exists
    if (!window.dataLayer || !Array.isArray(window.dataLayer)) {
      console.error('Google Data Layer is not initialized.');
      return;
    }

    // Ensure arguments are provided
    if (arguments.length === 0) {
      console.error('No arguments provided to push to Google Data Layer.');
      return;
    }

    // Push arguments to the Google Data Layer
    window.dataLayer.push(arguments);
  }

  updateConsentCookies(consent) {
    try {
      // Ensure the consent object is valid
      if (typeof consent !== 'object' || consent === null) {
        throw new Error('Invalid consent object');
      }

      // Convert the consent object to a JSON string
      const consentString = JSON.stringify(consent);

      // Set the cookie with the JSON string as the value
      document.cookie = `BexConsent=${consentString}; path=/; max-age=31536000`;
    } catch (error) {
      console.error('Failed to update consent cookies:', error);
    }

    // As long the table cookie_consents exists, we need to set the old cookies
    // (july 2024)
    if (this.element.dataset.consents) {
      let oldConsentsIdentifiers = [];
      try {
        oldConsentsIdentifiers = JSON.parse(this.element.dataset.consents);
      } catch (error) {
        console.error('Failed to parse consents data:', error);
        return;
      }

      const consentMapping = {
        essential: consent.necessary,
        marketing: consent.marketing,
        statistics: consent.statistics,
        preferences: consent.preferences,
      };

      const cookieExpiryDays = 365;
      const currentDate = new Date();
      currentDate.setTime(currentDate.getTime() + cookieExpiryDays * 24 * 60 * 60 * 1000);
      const expires = `expires=${currentDate.toUTCString()}`;

      oldConsentsIdentifiers.forEach(({ identifier, identifier_type }) => {
        const old_cookie_name = `cookie_consent_${identifier}`;
        const old_cookie_value =
          consentMapping[identifier_type] !== undefined ? (consentMapping[identifier_type] ? 'accepted' : 'refused') : 'accepted';
        document.cookie = `${old_cookie_name}=${old_cookie_value}; ${expires}; path=/`;
      });
    } else {
      console.warn('No consents data found.');
    }
  }

  getConsentCookieValues() {
    const name = 'BexConsent=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');

    for (let i = 0; i < ca.length; i++) {
      let c = ca[i].trim(); // Trim leading and trailing spaces

      if (c.indexOf(name) === 0) {
        const consentString = c.substring(name.length, c.length);

        try {
          return JSON.parse(consentString);
        } catch (error) {
          console.error('Failed to parse consent cookie:', error);
          return null;
        }
      }
    }

    return null;
  }

  deleteCookie(name) {
    document.cookie = `${name}=; Max-Age=-99999999; path=/`;
  }

  consentCookieExists() {
    // After the 1st of July 2025, we can remove this code part about the old cookies
    const oldCookie = this.getCookie('cookie_consent_dismissed');

    if (oldCookie && oldCookie === 'true') {
      const consent = {
        necessary: true,
        preferences: true,
        statistics: true,
        marketing: true,
        method: 'implied',
        ver: 1,
        utc: Date.now(),
      };

      const updatedCookieMapping = {
        statistiken: 'statistics',
        statistieken: 'statistics',
        google_analytics: 'statistics',
        marketings: 'marketing',
        marketing: 'marketing',
        facebook: 'marketing',
        praferenzen: 'preferences',
        voorkeuren: 'preferences',
      };

      Object.entries(updatedCookieMapping).forEach(([cookieName, consentType]) => {
        const cookieValue = this.getCookie(`cookie_consent_${cookieName}`);
        if (cookieValue) {
          if (cookieValue === 'accepted') {
            consent[consentType] = true;
          } else if (cookieValue === 'refused') {
            consent[consentType] = false;
          }
        }
      });

      this.updateConsentCookies(consent);
      this.deleteCookie('cookie_consent_dismissed');
    }

    return !!this.getCookie('BexConsent');
  }

  getCookie(name) {
    try {
      // Ensure the name parameter is a valid string
      if (typeof name !== 'string' || name.trim() === '') {
        throw new Error('Invalid cookie name');
      }

      // Create a regular expression to match the cookie name
      const regex = new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)');
      const matches = document.cookie.match(regex);

      // Return the decoded cookie value if matches are found
      return matches ? decodeURIComponent(matches[1]) : undefined;
    } catch (error) {
      console.error('Failed to get cookie:', error);
      return undefined;
    }
  }

  openDialog() {
    try {
      const dialog = this.element;
      if (dialog instanceof HTMLElement) {
        dialog.style.display = 'flex';
      } else {
        throw new Error('Invalid dialog element');
      }
    } catch (error) {
      console.error('Failed to open dialog:', error);
    }
  }

  closeDialog() {
    try {
      const dialog = this.element;
      if (dialog instanceof HTMLElement) {
        dialog.style.display = 'none';
      } else {
        throw new Error('Invalid dialog element');
      }
    } catch (error) {
      console.error('Failed to close dialog:', error);
    }
  }
}
